var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "span",
                _vm._g({ staticClass: "secondary--text subtitle-2" }, on),
                [
                  _c("a", [
                    _vm._v(
                      _vm._s(
                        _vm.truncateText(
                          _vm.itemData.command,
                          _vm.truncationLength
                        )
                      )
                    ),
                  ]),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticStyle: { "overflow-y": "hidden" } },
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "secondary--text" },
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v("mdi-script-text-outline"),
                ]),
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ],
              1
            ),
          ]),
          _c("v-divider", { staticClass: "mb-1" }),
          _c(
            "v-card-text",
            [
              _c("CodeHighlight", {
                attrs: { language: "sql", code: _vm.itemData.command },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("copy-to-clipboard", {
                attrs: {
                  textToCopy: _vm.itemData.command,
                  buttonClass: "",
                  buttonColor: "primary",
                  isTextButton: true,
                  buttonName: "copy to clipboard",
                  showIcon: false,
                  isSmall: false,
                },
              }),
              _vm.itemData.status_name ===
                _vm.queryStatusOptions.COMPLETED_QUERY &&
              _vm.itemData.mode_name === _vm.queryModeOptions.PREVIEW_MODE &&
              _vm.diffHours(_vm.itemData.finished) < 1440
                ? _c("QueryViewResults", {
                    attrs: {
                      qid: _vm.itemData.qid.toString(),
                      sql: _vm.itemData.command,
                      forDialog: true,
                    },
                    on: { queryResultsSuccess: _vm.moveToReviewTab },
                  })
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }